
import { Component, Vue } from 'nuxt-property-decorator'
@Component
export default class extends Vue {
  valueList: Array<any> = [
    {
      img: require('@/assets/images/home/mission.png'),
      title: '我们的使命',
      titleBg: 'MISSION',
      desc: '服务科研助力科技',
    },
    {
      img: require('@/assets/images/home/vision.png'),
      title: '我们的愿景',
      titleBg: 'VISION',
      desc: '成为享誉全球的<br/>中国科技仪器企业',
    },
  ]
}
