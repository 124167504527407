
import { service } from '@/db/home/service'
import { Component, Vue } from 'nuxt-property-decorator'
@Component
export default class extends Vue {
  serviceDetail: any = service
  activeTab: number = 0
  toBusiness(i: number) {
    window.sessionStorage.setItem('ACTIVETAB', i.toString())
    window.open('/business', '_self')
  }
}
