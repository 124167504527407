export let service = {
  title: '服务科研 助力科技<br/>构建数字渠道 实现产业互联',
  list: [
    {
      serviceImg: 'https://img.mumuxili.com/officialSite/mobile/home/serve-1.png',
      serviceTopic: '科技服务业务',
      serviceDescription: '全流程服务，方便科研每一步',
    },
    {
      serviceImg: 'https://img.mumuxili.com/officialSite/mobile/home/serve-2.png',
      serviceTopic: '科技仪器业务',
      serviceDescription: '自主创新，产业报国',
    },
    {
      serviceImg: 'https://img.mumuxili.com/officialSite/mobile/home/serve-3.png',
      serviceTopic: 'SAAS业务',
      serviceDescription: '数字升级，商机无限，行业互联',
    },
  ]
}
