export let cases =  [
  [
    {
      img: require('@/assets/images/home/case-1.png'),
      title: '盐城市城南新区开发建设投资有限公司',
      subTitle: '由盐城政府牵头，南航老师入驻，专攻3D打印。按照初始200万的预算推荐3D打印方向相关的设备，沟通了多版方案，确定了具体流程，最后成功中标，12月份完成设备的验收工作。',
      tagList: ['手持激光诱导击穿光谱仪 | 星帆华镭 CUSTOM-2', '金属粉末粒度分析仪 | 欧美克 TopSizer']
    },
    {
      img: require('@/assets/images/home/case-2.png'),
      title: '江苏易诺维生物医学研究院整体实验室搭建项目',
      subTitle: '超微量紫外-可见光分光光度计、高压灭菌锅、细胞计数仪、倒置相差荧光显微镜、高速冷冻离心机、台式冻干系统。',
      tagList: ['超微量紫外-可见光分光光度计 | 赛默飞 NanoDrop one', '高压灭菌锅 | 日本TOMY SX-500', '细胞计数仪 | 赛默飞 Countess Ⅱ', '倒置相差荧光显微镜 | 尼康 TS2-FL', '高速冷冻离心机 | 赛默飞 ST16R', '台式冻干系统 | Labconco FreeZone 2.5L']
    },
    {
      img: require('@/assets/images/home/case-3.png'),
      title: '混凝土结构质量综合检测仪',
      subTitle: '检测混凝土的注浆质量，检测混凝土和衬砌之间是否有脱空的情况。能够探测1米左右的范围，并且不受金属材质的钢筋网的干扰。探测的空洞的误差在3cm之内。',
      tagList: ['混凝土结构质量综合检测仪LT-CQST'],
    },
  ],
  [
    {
      img: require('@/assets/images/home/case-4.png'),
      title: '中国矿业大学 安全工程学院——静电计',
      subTitle: '用于预警煤矿，岩石等裂纹发生，对于煤矿安全，生产安全有很大的经济效益。除了设备，我方还为客户提供了定制软件，能够四台设备同时采集相关数据并显示，让客户的试验更加贴近现场工况。',
      tagList: ['静电计 | 6517B']
    },
    {
      img: require('@/assets/images/home/case-5.png'),
      title: '山东大学前沿交叉科学青岛研究院——在线气体分析质谱仪',
      subTitle: '检测痕量气体成分（CNO），分子量300以下，一次性通入的气体在2-100ml左右，进气速度没有要求。配置：DECRA主机+60l/s的涡轮分子泵+QIC快速毛细管取样系统+MASsof软件。',
      tagList: ['Hiden  DECRA']
    },
    {
      img: require('@/assets/images/home/case-6.png'),
      title: '山东科技大学 安全与环境学院——声发射仪（DS5-8A）',
      subTitle: '客户应用方向，煤自燃前期动态预警，用声发射仪配合次声波传感器实时采集声信号，分析研究煤自燃前声信号的各种特征。',
      tagList: ['紫外可见吸收光谱仪 | 岛津'],
    },
  ]
]