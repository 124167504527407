
import { cases } from '@/db/home/cooperation'
import { formatDate } from '@/utils/utils'
import { NuxtApp } from '@nuxt/types/app'
import { Component, Vue } from 'nuxt-property-decorator'
@Component
export default class extends Vue {
  cooperationList: Array<any> = cases[0]
  trendsList: Array<any> = []
  bannerList: any[] = []
  head(this: any): object {
    return {
      title: '木木西里官网',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: '',
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: '',
        },
      ],
    }
  }
  async asyncData({ app }: { app: NuxtApp }) {
    let bannerList = [], trendsList = []
    const { queryAdvertisement, queryArticleList } = app.$apis.common
    const bannerRes = await queryAdvertisement({ name: 'GROUP_MOBILE_HOME_BANNER', count: 20 })

    if (bannerRes.success) {
      bannerList = bannerRes?.data?.[0]?.imgs || []
    }
    const articleRes = await queryArticleList() // 最新动向
    if (articleRes?.code === '0') {
      trendsList = articleRes.data.map(e => {
        return {
          id: e.articleId,
          img: e.cover,
          title: e.title,
          subTitle: e?.articleContent?.articleAbstract || '',
          source: e.originAddress,
          time: formatDate(e.publishTime),
        }
      })
    }

    return  {
      bannerList,
      trendsList
    }
  }
  async created() {
    // const res = await this.$apis.common.queryArticleList() // 最新动向
    // if (res?.code === '0') {
    //   this.trendsList = res.data.map(e => {
    //     return {
    //       id: e.articleId,
    //       img: e.cover,
    //       title: e.title,
    //       subTitle: e?.articleContent?.articleAbstract || '',
    //       source: e.originAddress,
    //       time: formatDate(e.publishTime),
    //     }
    //   })
    // } else {
    //   this.trendsList = []
    // }
  }
  handleBannerClick(item) {
    if (item?.url && item?.type == 2) {
      window.open(item.url)
    }
  }
}
