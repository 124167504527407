import { render, staticRenderFns } from "./Display.vue?vue&type=template&id=36593bce&scoped=true&"
import script from "./Display.vue?vue&type=script&lang=ts&"
export * from "./Display.vue?vue&type=script&lang=ts&"
import style0 from "./Display.vue?vue&type=style&index=0&id=36593bce&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36593bce",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MumuTitle: require('/root/workspace/mumuxili-mobile-front_KVKI/components/Mumu/Title.vue').default,MumuEntry: require('/root/workspace/mumuxili-mobile-front_KVKI/components/Mumu/Entry.vue').default})
