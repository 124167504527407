import { render, staticRenderFns } from "./index.vue?vue&type=template&id=252f6848&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=252f6848&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "252f6848",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeDisplay: require('/root/workspace/mumuxili-mobile-front_KVKI/components/Home/Display.vue').default,HomeService: require('/root/workspace/mumuxili-mobile-front_KVKI/components/Home/Service.vue').default,HomeMarket: require('/root/workspace/mumuxili-mobile-front_KVKI/components/Home/Market.vue').default,HomeTrends: require('/root/workspace/mumuxili-mobile-front_KVKI/components/Home/Trends.vue').default})
