
// @ts-ignore
import VClamp from 'vue-clamp'
import { Component, Vue, Prop } from 'nuxt-property-decorator'
@Component({
  components: {
    VClamp,
  },
})
export default class extends Vue {
  @Prop({ default: () => [] }) trendsList!: Array<any>
  handleItemTap(item: any) {
    // window.location.href = `/connection/dynamics?id=${item.e.id}`
    window.open(`/connection/dynamics?id=${item.e.id}`, '_blank')
  }
}
