
import { Component, Prop, Vue } from 'nuxt-property-decorator'
@Component
export default class extends Vue {
  @Prop({ default: '' }) entryText!: string
  @Prop({ default: '' }) entryUrl!: string
  @Prop({ default: 1 }) entryType!: number // 1-页面内跳转 2-打开新网址
  @Prop({ default: false }) newPageOpen!: boolean
  @Prop({ default: 0 }) iconType!: number
  arrowIcon: Array<any> = [
    require('@/assets/images/common/icon-btn-arrow.svg'),
    require('@/assets/icon/common/blue-arrow-small.svg'),
  ]
  toSomewhere() {
    if (this.entryType == 3 && this.entryUrl) {
      window.open(this.entryUrl)
    } else if (this.entryType == 2 && this.entryUrl) {
      window.open('https://' + this.entryUrl)
    } else if (this.entryUrl) {
      let openType = this.newPageOpen ? '_blank' : '_self'
      window.open(this.entryUrl, openType)
    }
  }
}
